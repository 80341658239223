import { Component, OnInit } from '@angular/core';
import { GameService } from '../game.service';

@Component({
  selector: 'app-create-game',
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.css'],
})
export class CreateGameComponent implements OnInit {

  constructor(
    private gameService: GameService) {
    this.codeSizeList = [
      {name: 'Four', value: '4'},
      {name: 'Six', value: '6'},
      {name: 'Eight', value: '8'},
    ];

  }

  codeSize: number;
  codeSizeList: any;
  codeSizeSelected: number;

  ngOnInit() {
  }

  onItemChange() {
    this.codeSize = this.codeSizeSelected;
  }

  createGame(): void {
    this.gameService.createGame(this.codeSize)
      .subscribe(
        game => {
          console.log(game);
          window.location.href = '/game/' + game.id;
        });
  }
}
