import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameService } from '../game.service';
import { Game } from '../game';

@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.css']
})
export class PlayGameComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private gameService: GameService) { }

  @Input() game: Game;

  ngOnInit() {
    this.getGame();
  }

  getGame(): void {
    const gameKey = this.route.snapshot.paramMap.get('gameKey');
    this.gameService.getGame(gameKey)
    .subscribe(game => this.game = game);
  }
}
