import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { Game } from './game';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  private gameUrl = `${environment.apiUrl}/game`;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  /** Log a message with the MessageService */
  private log(message: string) {
    this.messageService.add(`GameService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** GET game by key. Will 404 if id not found */
  getGame(gameKey: string): Observable<Game> {
    const url = `${this.gameUrl}/${gameKey}`;
    return this.http.get<Game>(url).pipe(
      tap(_ => this.log(`fetched game key=${gameKey}`)),
      catchError(this.handleError<Game>(`getGame id=${gameKey}`))
    );
  }

  /** CREATE a new game with desired code size */
  createGame(codeSize: number): Observable<Game> {
    const url = `${this.gameUrl}`;
    return this.http.post<Game>(url, '{"secretCodeSize": "' + codeSize + '"}', this.httpOptions).pipe(
      tap(_ => this.log(`created game`),
      catchError(this.handleError<Game>(`error creating game`))
    ));
  }
}
